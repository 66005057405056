import React from "react"
import PropTypes from "prop-types"

import Layout from "../../components/Layout/Layout"
import GlobalContext from "../../context/GlobalContext"
import { setDataLayer } from "../../helpers/dataLayerSetter"

import componentMaker from "../../helpers/ComponentMaker/componentMaker"
import ContentHero from "../../components/ContentHero/ContentHero"
import ContentRelatedBlocks from "../../components/ContentRelatedBlocks/ContentRelatedBlocks"
import { gTagScriptConsent } from "../../components/Scripts/Gtm.Head"

const BrandContentDetail = ({ pageContext, path }) => {

    const { globalProps, seoData, pageType, dataLayer, globalLabels, brandTheme } = pageContext
    const {
        mainTitle,
        subTitle,
        heroLink,
        isHeroLinkSticky,
        relatedContent,
    } = pageContext?.pageProps?.contentfulBrandContentDetail || {}

    setDataLayer(dataLayer, seoData, pageType)

    return (
        <GlobalContext labels={globalLabels} brandTheme={brandTheme}>
            <Layout path={path} seoData={seoData} globalProps={globalProps}>
                {mainTitle && (
                    <ContentHero
                        brandLogo={brandTheme?.logo}
                        title={mainTitle}
                        subTitle={subTitle?.subTitle}
                        heroLink={heroLink}
                        isHeroLinkSticky={isHeroLinkSticky}
                        shareUrl={path}
                    />
                )}
                <section className="brand-content-detail relative max-w-[1052px] px-[15px] md:px-10 mx-auto mb-[97px]">
                    {componentMaker(pageContext.pageProps?.staticResult?.contentComponents)}
                </section>
                {relatedContent && (
                    <ContentRelatedBlocks
                        additionalClass="content-related-blocks w-full lg:w-10/12"
                        headerVariant="RELATED_BLOCKS"
                        {...relatedContent}
                    />
                )}
            </Layout>
        </GlobalContext>
    )
}

BrandContentDetail.propTypes = {
    pageContext: PropTypes.shape({
        pageProps: PropTypes.object,
        globalProps: PropTypes.object,
        seoData: PropTypes.object,
        pageType: PropTypes.string,
        dataLayer: PropTypes.object,
        globalLabels: PropTypes.object,
        brandTheme: PropTypes.object,
    }),
    path: PropTypes.string,
}

/**
 * Adding to Layout ?
 */
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
            />
        </>
    )
}

export default BrandContentDetail
